<template>
  <div>
    <v-card>
      <v-card-title class='px-2'>
        <Comeback />
        {{ $t('Report Commission') }}
        <v-spacer></v-spacer>
        <v-btn
          color='primary'
          :loading='exportLoading'
          class='d-none d-md-block'
          :disabled='exportLoading'
          @click='exportExcel'
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color='primary'
          class='d-block d-md-none'
          :loading='exportLoading'
          :disabled='exportLoading'
          icon
          fab
          outlined
          @click='exportExcel'
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <DateFilters @onSendDate='addPayload' />
      <v-row class='px-2'>
        <v-col cols='12' md='6' lg='3' class='py-0'>
          <v-select
            v-model='teamSelected'
            :items='teamList'
            :label="$t('staff_team')"
            item-text='teams_name'
            item-value='teams_id'
            outlined
            dense
            @change='addPayload(payload.start, payload.end)'
          ></v-select>
        </v-col>
        <v-col cols='12' md='6' lg='3' class='py-0'>
          <v-select
            v-model='typeSelected'
            outlined
            dense
            :items='typeList'
            :label="$t('type')"
            :item-text='$i18n.locale'
            item-value='value'
            @change='addPayload(payload.start, payload.end)'
          ></v-select>
        </v-col>
        <v-col cols='12' md='6' lg='3' class='py-0'>
          <v-text-field
            v-model='searchtext'
            dense
            outlined
            :label="`${$t('search')} (${$t('pressEnterForSearch')})`"
            @keypress.enter='addPayload(payload.start, payload.end)'
          >
          </v-text-field>
        </v-col>
        <v-col cols='12' md='6' lg='3' class='py-0'>
          <v-select
            v-model='optionsSelection'
            outlined
            dense
            :items='optionsList'
            :label="$t('type')"
            item-text='text'
            item-value='value'
            @change='addPayload(payload.start, payload.end)'
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers='columns'
        :items='dataTableList'
        :options.sync='options'
        :loading='loading'
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.order_id`]='{ item }'>
          <router-link
            v-if='item.order_id'
            :to="{ name: 'order-detail', params: { id: item.order_id_pri } }"
            class='font-weight-medium text-decoration-none'
            target='_blank'
          >
            {{ item.order_id }}
          </router-link>
        </template>
        <template v-slot:[`item.order_date`]='{ item }'>
          {{ item.order_date | sumdatetime }}
        </template>
        <template v-slot:[`item.customer_name`]='{ item }'>
          <span :class="item.customer_name == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.customer_name }}
          </span>
        </template>
        <template v-slot:[`item.amount`]='{ item }'>
          <span :class="item.customer_name == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.amount }}
          </span>
        </template>
      </v-data-table>
      <v-data-table
        :headers='columns2'
        :items='dataTableList2'
        :options.sync='options'
        :loading='loading'
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiFileExcelOutline } from '@mdi/js'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import { i18n } from '@/plugins/i18n'
import { reportCommis, removeComma } from '../useExcel'
import Comeback from '../Comeback.vue'
import { sumdate, sumdatetime } from '@/plugins/filters'
import CommissiomStatus from '@/@fake-db/data/CommissiomStatus.json'
import teamReport from '@/api/teamReport'

export default {
  components: {
    DateFilters,
    Comeback,
  },
  filters: {
    sumdatetime,
  },
  setup() {
    const XLSX = require('xlsx')
    const payload = ref({})
    const loading = ref(false)
    const dataTableList = ref([])
    const dataTableList2 = ref([])
    const dataExcel = ref([])
    const optionsSelection = ref(1)
    const optionsList = ref([
      { value: 1, text: i18n.t('showCommissiontReceipt') },
      { value: 2, text: i18n.t('showCommissionInstallment') },
    ])
    const options = ref({})
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('receipt_number'), value: 'order_id', width: 130 },
      { text: i18n.t('staff_team'), value: 'teams_name', width: 190 },
      { text: i18n.t('code'), value: 'orderdetail_id', width: 110 },
      { text: i18n.t('list'), value: 'orderdetail_name', width: 260 },
      { text: i18n.t('type'), value: 'type', width: 160 },
      { text: i18n.t('customer'), value: 'customer_name', width: 220 },
      {
        text: i18n.t('net_amount'),
        value: 'amount',
        width: 180,
        align: 'end',
      },
      { text: i18n.t('date'), value: 'order_date', width: 180 },
    ])
    const columns2 = ref([
      { text: i18n.t('staff_team'), value: 'teams_name', width: 130 },
      {
        text: i18n.t('check'),
        value: 'check_total',
        width: 130,
        align: 'end',
      },
      {
        text: i18n.t('course'),
        value: 'course_total',
        width: 130,
        align: 'end',
      },
      {
        text: i18n.t('drug'),
        value: 'drug_total',
        width: 130,
        align: 'end',
      },
      {
        text: i18n.t('equipment'),
        value: 'equipment_total',
        width: 130,
        align: 'end',
      },
      {
        text: i18n.t('memberCard'),
        value: 'member_total',
        width: 130,
        align: 'end',
      },
      {
        text: i18n.t('net_amount'),
        value: 'total',
        width: 130,
        align: 'end',
      },
    ])
    const exportLoading = ref(false)
    const typeList = ref(CommissiomStatus.data)
    const typeSelected = ref('')
    const searchtext = ref('')
    const teamList = ref([])
    const teamSelected = ref('')

    teamReport.teamReportList().then(res => {
      teamList.value = res
      teamList.value.unshift({
        teams_id: '',
        teams_name: i18n.t('all'),
      })
    })

    const addPayload = (start, end) => {
      payload.value = {
        start,
        end,
        teams_id: teamSelected.value,
        type_id: typeSelected.value,
        searchtext: searchtext.value,
        lang: i18n.locale,
      }
      searchReport(payload.value)
    }
    const mapDataTeam = datateam => {
      try {
        const data = datateam.map(item => ({
          teams_name: item.teams_name,
          orderdetail_id: item.course_total,
          orderdetail_name: item.drug_total,
          type: item.equipment_total,
          customer_name: item.member_total,
          amount: item.total,
        }))

        return [
          {
            teams_name: i18n.t('staff_team'),
            orderdetail_id: i18n.t('course'),
            orderdetail_name: i18n.t('drug'),
            type: i18n.t('equipment'),
            customer_name: i18n.t('memberCard'),
            amount: i18n.t('net_amount'),
          },
          ...data,
        ]
      } catch (err) {
        console.error(err)

        return []
      }
    }
    const searchReport = async payload => {
      loading.value = true
      const {
        data,
        datateam,
      } = await reportCommis(payload, optionsSelection.value == 1 ? 'reportcommis' : 'reportcommis2')
      dataTableList.value = data
      dataTableList2.value = datateam
      dataExcel.value = mapDataTeam(datateam)
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    const exportExcel = async () => {
      exportLoading.value = true
      const { start, end } = payload.value
      const fileName = `${i18n.t('ReportCommission')} ${i18n.t('since')} ${sumdate(start)} ${i18n.t('to')} ${sumdate(
        end,
      )} .xlsx`
      let dataExport = dataTableList.value.map((item, i) => {
        delete item.order_id_pri

        return { number: i + 1, ...item }
      })
      dataExport = await removeComma(JSON.parse(JSON.stringify([...dataExport, {}, {}, ...dataExcel.value])))

      // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx
      /*  รายงานวิเคราะห์รายการตรวจ */
      const Heading = [
        [`${i18n.t('ReportCommission')} ${i18n.t('since')} ${sumdate(start)} ${i18n.t('to')} ${sumdate(end)} `],
        [
          '#',
          `${i18n.t('receipt_number')}`,
          `${i18n.t('staff_team')}`,
          `${i18n.t('code')}`,
          `${i18n.t('list')}`,
          `${i18n.t('type')}`,
          `${i18n.t('customer')}`,
          `${i18n.t('net_amount')}`,
          `${i18n.t('date')}`,
        ],
      ]

      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataExport, {
        header: [
          'number',
          'order_id',
          'teams_name',
          'orderdetail_id',
          'orderdetail_name',
          'type',
          'customer_name',
          'amount',
          'order_date',
        ],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 8 } }]
      const wscols = [
        { wch: 12 },
        { wch: 12 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
        { wch: 18 },
        { wch: 20 },
        { wch: 12 },
        { wch: 12 },
        { wch: 15 },
        { wch: 15 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(wb, ws, `${i18n.t('ReportCommission')}`)

      /* พิมร์ files */
      XLSX.writeFile(wb, fileName)

      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      XLSX,
      payload,
      loading,
      dataTableList,
      dataTableList2,
      options,
      columns,
      columns2,
      exportLoading,
      typeList,
      typeSelected,
      searchtext,
      teamList,
      teamSelected,
      optionsList,
      optionsSelection,
      exportExcel,
      addPayload,
      mdiFileExcelOutline,
    }
  },
}
</script>
