import { i18n } from '@/plugins/i18n'
import refreshToken from '@/plugins/refresh-token'
import { api } from './main'

const teamReportList = async () => {
  //await refreshToken()
  const response = await api
    .get({
      path: 'ref/teamsuserreport',
      param: i18n.locale,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.error('get use team list error : ', err)

      return []
    })

  return response
}
export default { teamReportList }
